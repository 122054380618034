import Flatpickr from 'stimulus-flatpickr'
import { Polish } from 'flatpickr/dist/l10n/pl.js'

export default class extends Flatpickr {
  initialize() {
    this.config = {
      locale: Polish,
      minDate: "2022-09-01",
      maxDate: new Date(),
      disable: [
        (date) => (date.getDay() === 0 || date.getDay() === 6)
      ]
    }
  }

  static get targets() {
    return ['instance']
  }

  // all flatpickr hooks are available as callbacks in your Stimulus controller
  change(_selectedDates, dateStr, _instance) {
    window.location.href = `?${this.instanceTarget.dataset.queryParam}=${dateStr}#${this.instanceTarget.dataset.queryParam}`;
  }
}
