import { Controller } from 'stimulus'

export default class extends Controller {
  static get targets() {
    return ['dayOption', 'slotCheckbox']
  }

  connect() {
    console.log("AttendancesTableRowController connected")
  }

  studentPresent() {
    this.slotCheckboxTargets.forEach(checkbox => {
      checkbox.checked = true
    });
  }
}
