import { Controller } from 'stimulus'

export default class extends Controller {
  static get targets() {
    return []
  }

  connect() {
    console.log("SampleComponentNameController connected")
  }
}
