// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from '@rails/ujs'
import Turbolinks from 'turbolinks'
import { Application } from 'stimulus'
import { definitionsFromContext } from 'stimulus/webpack-helpers'
import * as ActiveStorage from '@rails/activestorage'
// import './channels'

Rails.start()
Turbolinks.start()
ActiveStorage.start()

const application = Application.start()
const usersContext = require.context('../modules/users/views', true, /\.js$/)
const teachersContext = require.context('../modules/teachers/views', true, /\.js$/)
const adminsContext = require.context('../modules/admins/views', true, /\.js$/)
const sharedViewsContext = require.context('../modules/shared/views', true, /\.js$/)
const sharedComponentsContext = require.context('../modules/shared/components', true, /\.js$/)
application.load(definitionsFromContext(sharedComponentsContext))
application.load(definitionsFromContext(sharedViewsContext))
application.load(definitionsFromContext(usersContext))
application.load(definitionsFromContext(teachersContext))
application.load(definitionsFromContext(adminsContext))

// import Flatpickr from 'stimulus-flatpickr'
import Flatpickr from './js/controllers/flatpickr_controller'

// Import style for flatpickr
require('flatpickr/dist/flatpickr.css')

// Manually register Flatpickr as a stimulus controller
application.register('flatpickr', Flatpickr)
