import { Controller } from 'stimulus'
import axiosClient from 'Services/axios_client'
import Toast from 'bootstrap/js/src/toast'

export default class extends Controller {
  static get targets() {
    return ['dayOption', 'slotCheckbox', 'saveButton', 'cancelButton']
  }

  static get values() {
    return {
      dateOn: String
    }
  }

  connect() {
    console.log("AttendancesTableController connected")
  }

  checkIfDirty() {
    if (this.slotCheckboxTargets.every((checkbox) => checkbox.checked.toString() === checkbox.dataset.initialValue)) {
      this.saveButtonTarget.setAttribute('disabled', true)
      this.cancelButtonTarget.setAttribute('disabled', true)
    } else {
      this.saveButtonTarget.removeAttribute('disabled')
      this.cancelButtonTarget.removeAttribute('disabled')
    }
  }

  cancel() {
    this.slotCheckboxTargets.forEach((checkbox) => {
      checkbox.checked = (checkbox.dataset.initialValue === 'true')
    })
    this.checkIfDirty()
  }

  save() {
    const data = {
      date: this.dateOnValue
    }

    const slots = this.slotCheckboxTargets.reduce((res, checkbox) => {
      const studentId = parseInt(checkbox.dataset.studentId, 10)
      res[studentId] = res[studentId] || []
      res[studentId].push({
        id: parseInt(checkbox.dataset.slotId, 10),
        presence: checkbox.checked
      })
      return res
    }, {});

    data.students = Object.entries(slots).map(([studentId, slots]) => (
      {
        id: parseInt(studentId, 10),
        slots: slots
      }
    ))

    // co jesli uczen opusci klase? przejdzie do innej?
    // musimy trzymac dane
    axiosClient.put(
      '/nauczyciel/obecnosc',
      data,
      () => {
        const toast = Toast.getOrCreateInstance(document.getElementById('changes-saved-toast'))
        toast.show()
        setTimeout(() => window.location.reload(), 500)
      }
    )

    // {
    //   date: "02-01-2022",
    //   students: [
    //     {
    //       id: 1,
    //       slots: [
    //         {
    //           id: 2,
    //           presence: true
    //         },
    //         {
    //           id: 5,
    //           presence: false
    //         }
    //       ]
    //     }
    //   ]
    // }
  }
}
