import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    this.element.classList.add(this.mobileOS())
  }

  mobileOS() {
    if (navigator.userAgent.match(/Android/i)) {
      return 'android'
    } else if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
      return 'ios'
    } else {
      return 'desktop'
    }
  }
}
