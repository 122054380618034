import { Controller } from 'stimulus'

export default class extends Controller {
  static get targets() {
    return ['avatarUpload', 'avatar', 'onlineStatus']
  }

  connect() {
    console.log("Settings Controller connected")

    console.log("request notification permission")
    Notification.requestPermission().then((permission) => {
      console.log("notification permission is:", permission)
      this.setPushState()
    })
  }

  avatarUploaded() {
    const [file] = this.avatarUploadTarget.files
    if (file) {
      this.avatarTarget.src = URL.createObjectURL(file)
    }
  }

  setPushState() {
    if (!("Notification" in window)) {
      this.onlineStatusTarget.innerHTML = 'Notyfikacje nie są wspierane na obecnym urządzeniu'
    }
    else if (Notification.permission === 'granted') {
      this.onlineStatusTarget.innerHTML = '<i class="fas fa-check text-success"></i> Notyfikacje są włączone na tym urządzeniu.'
    } else {
      this.onlineStatusTarget.innerHTML = '<i class="fas fa-exclamation-triangle text-warning"></i> Przejdź do ustawień notyfikacji na swoim urządzeniu aby je włączyć.'
    }
  }
}
