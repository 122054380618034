import { Controller } from 'stimulus'
import List from 'list.js'

export default class extends Controller {
  static get targets() {
    return ['searchText']
  }

  connect() {
    this.listing = new List('users', {
      valueNames: ['name', 'classroom']
    });
  }

  get searchText() {
    return this.searchTextTarget.value
  }

  search() {
    this.listing.search(this.searchText);
  }
}
