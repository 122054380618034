import { Controller } from 'stimulus'
import axiosClient from 'Services/axios_client'
import Modal from 'bootstrap/js/src/modal'
import Tooltip from 'bootstrap/js/src/tooltip'

export default class extends Controller {
  static get targets() {
    return ['topicSubject', 'editedTopicSubject', 'scheduleTopic', 'scheduleTopicModal', 'editTopicModal', 'addScheduleTopicBtn']
  }

  static get values() {
    return {
      id: Number,
      dateOn: String,
      originTopicSubject: String
    }
  }

  connect() {
    console.log("ScheduleSlotController connected")

    this.enableTooltips()

    if (this.hasEditTopicModalTarget) {
      this.editTopicModalTarget.addEventListener('shown.bs.modal', () => {
        this.editedTopicSubjectTarget.focus()
      })

      this.editTopicModalTarget.addEventListener('hidden.bs.modal', () => {
        // reset nie działa
        // this.editedTopicSubjectTarget.value = this.originTopicSubjectValue
      })
    }
  }

  enableTooltips() {
    if (this.hasScheduleTopicTarget) {
      const tooltipTriggerList = [].slice.call(this.scheduleTopicTarget.querySelectorAll('[data-bs-toggle="tooltip"]'))
      tooltipTriggerList.map((tooltipTriggerEl) => new Tooltip(tooltipTriggerEl))
    }
  }

  showTopicModal() {
    this.modal = new Modal(this.scheduleTopicModalTarget, {})
    this.scheduleTopicModalTarget.addEventListener('shown.bs.modal', () => {
      this.topicSubjectTarget.focus()
    })
    this.modal.show()
  }

  editTopicModal() {
    this.editModal = new Modal(this.editTopicModalTarget, {})
    this.editModal.show()
  }

  createTopic() {
    const scheduleTopic = {
      date_on: this.dateOnValue,
      subject: this.topicSubjectTarget.value
    }

    axiosClient.post(
      '/nauczyciel/tematy_zajec',
      {
        id: this.idValue,
        schedule_topic: scheduleTopic
      },
      (_code, res) => {
        this.scheduleTopicTarget.innerHTML = res['schedule_topic']
        this.modal.hide()
        this.enableTooltips()
      }
    )
  }

  editTopic() {
    axiosClient.put(
      `/nauczyciel/tematy_zajec/${this.editTopicModalTarget.dataset.topicId}`,
      {
        subject: this.editedTopicSubjectTarget.value
      },
      (_code, res) => {
        this.scheduleTopicTarget.innerHTML = res['schedule_topic']
        this.editModal.hide()
        this.enableTooltips()
      }
    )
  }
}
