import { Controller } from 'stimulus'

export default class extends Controller {
  static get targets() {
    return ['avatarUpload', 'avatar']
  }

  connect() {
    console.log("PickAvatar connected")
  }

  avatarUploaded(e) {
    const [file] = this.avatarUploadTarget.files

    if (file) {
      this.avatarTarget.src = URL.createObjectURL(file)
    }
  }
}
