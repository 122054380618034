import { Controller } from "stimulus"
import html2canvas from "html2canvas"

export default class extends Controller {
  static get targets() {
    return ['scheduleTable']
  }

  connect() {
    console.log("WeeklyScheduleTableController connected")
  }

  download(e) {
    const doc = new window.jspdf.jsPDF({
      // orientation: 'l',
      unit: 'px',
      format: 'a4',
      hotfixes: ['px_scaling']
    });

    const elWidth = this.element.scrollWidth
    const elHeight = this.scheduleTableTarget.scrollHeight
    const ratio = elHeight / elWidth

    html2canvas(this.scheduleTableTarget, {
      ignoreElements: (e) => {
        if (['BUTTON', 'I', 'A', 'P', 'SVG', 'PATH'].includes(e.tagName) || e.classList.contains("cursor-pointer")) {
          return true
        }
        return false
      }
    }).then(function(canvas) {
      const img = canvas.toDataURL("image/png");

      // przeskalowac tak aby plan sie zmiescil w A4 (brac ratio, liczyc jaki ten obrazek ma byc maksymalnie)
      doc.addImage(img, "PNG", 2, 0, doc.internal.pageSize.getWidth() - 4, Math.min(doc.internal.pageSize.getHeight(), ratio * doc.internal.pageSize.getWidth()));
      // doc.addImage(img, "PNG", 2, 0, doc.internal.pageSize.getWidth() - 4, Math.min(doc.internal.pageSize.getHeight(), elHeight));
      doc.save("plan.pdf");
    });
  }
}
